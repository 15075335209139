<template>
<!-- Generator: Adobe Illustrator 27.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 85 85" style="enable-background:new 0 0 85 85;" xml:space="preserve">
<style type="text/css">
	.st0{enable-background:new    ;}
	.st1{fill:#216BFF;}
</style>
<g class="st0">
	<path class="st1" d="M8.2,70.7l0,7.4l2,0l0,2.2l-6.8,0l0-2.2l2,0l0-7.4l-2,0l0-2.2l6.8,0l0,2.2L8.2,70.7z"/>
	<path class="st1" d="M11.5,74.4c0-3.5,2.7-6,6.4-6c3.7,0,6.4,2.6,6.4,6.1s-2.7,6-6.4,6C14.1,80.5,11.4,77.9,11.5,74.4z M21.5,74.4
		c0-2.2-1.6-3.7-3.7-3.8c-2.1,0-3.7,1.5-3.7,3.7c0,2.2,1.6,3.7,3.7,3.8C19.9,78.2,21.5,76.7,21.5,74.4z"/>
	<path class="st1" d="M34.7,71.9c-1-0.6-2.1-1-3.2-1.1l0,9.5l-2.7,0l0-9.5c-1.2,0.2-2.2,0.5-3.2,1.1l-0.9-2c1.6-1,3.5-1.4,5.5-1.4
		c2,0,3.9,0.5,5.5,1.5L34.7,71.9z"/>
	<path class="st1" d="M40.8,70.8l0,7.4l2,0l0,2.2l-6.8,0l0-2.2l2,0l0-7.4l-2,0l0-2.2l6.8,0l0,2.2L40.8,70.8z"/>
	<path class="st1" d="M55.5,73.5l0,6.9l-2.7,0l0-6.8c0-1.9-1-2.8-2.5-2.8c-1.6,0-2.7,0.9-2.7,3l0,6.6l-2.7,0l0-11.7l2.6,0l0,1.4
		c0.8-1.1,2-1.6,3.6-1.6C53.9,68.5,55.5,70.2,55.5,73.5z"/>
	<path class="st1" d="M66.1,74.3l2.5,0l0,6l-2,0l0-0.7c-0.8,0.6-1.9,0.9-3.1,0.9c-3.2,0-5.9-2.1-5.9-6c0-3.6,2.7-6.1,6.4-6.1
		c2.1,0,3.8,0.7,4.9,2L67,72.1c-0.9-0.9-1.8-1.3-3-1.3c-2.3,0-3.8,1.5-3.8,3.8c0,2.5,1.6,3.7,3.5,3.7c0.9,0,1.7-0.2,2.4-0.8
		L66.1,74.3z"/>
	<path class="st1" d="M81.7,74l0,6.4l-2.7,0l0-2.8l-5.3,0l0,2.8l-2.7,0l0-6.4c0-3.6,2.2-5.5,5.4-5.5S81.7,70.5,81.7,74z M79,75.4
		l0-1.7c0-2-1.1-2.9-2.7-2.9c-1.6,0-2.7,0.9-2.7,2.9l0,1.7L79,75.4z"/>
</g>
<path class="st1" d="M68.4,23.7c-0.7-2.1-1.6-4-2.7-5.8c-0.9,1-2.1,1.7-3.4,2.2c-4.3,1.4-8.8-0.9-10.2-5.2c-0.9-2.7-0.2-5.6,1.5-7.7
	c-6-2.7-12.9-3.2-19.6-1c-14.5,4.7-22.3,20.1-17.6,34.5s20.1,22.2,34.5,17.5S73,38.1,68.4,23.7z M61.6,42.7
	c-0.9,7.9-8.1,13.5-16,12.6s-13.5-8.1-12.6-16c0.9-7.9,8.1-13.5,16-12.6C56.9,27.7,62.6,34.8,61.6,42.7z"/>
<circle class="st1" cx="59.8" cy="12.4" r="5.8"/>
</svg>
<!--
  <svg
    height="40"
    width="40"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 511.998 511.998"
    xml:space="preserve"
  >
    <path
      style="fill:#AA968C;"
      d="M503.999,247.999c0,128.13-111.033,240-248,240S8,376.129,8,247.999s111.033-224,248-224
    S503.999,119.869,503.999,247.999z"
    />
    <path
      style="fill:#AA968C;"
      d="M255.999,23.999C119.033,23.999,8,119.868,8,247.999c0,24.631,4.138,48.647,11.74,71.397
    c25.518,34.29,66.232,56.602,112.26,56.602c53.893,0,100.6-30.495,124-75.13c23.4,44.636,70.107,75.13,124,75.13
    c46.028,0,86.743-22.312,112.26-56.602c7.602-22.75,11.74-46.767,11.74-71.397C503.999,119.868,392.966,23.999,255.999,23.999z"
    />
    <circle
      style="fill:#C8AF9B;"
      cx="256"
      cy="351.999"
      r="136"
    />
    <g>
      <circle
        style="fill:#464655;"
        cx="132"
        cy="203.999"
        r="28"
      />
      <circle
        style="fill:#464655;"
        cx="380"
        cy="203.999"
        r="28"
      />
      <path
        style="fill:#464655;"
        d="M269.949,284.516c-7.672,10.741-20.227,10.741-27.899,0l-12.101-16.941
      c-7.672-10.741-3.15-19.53,10.05-19.53h32c13.2,0,17.723,8.788,10.05,19.53L269.949,284.516z"
      />
    </g>
    <path
      style="fill:#AA968C;"
      d="M 350.964 399.998 C 316.628 399.998 299.021 351.998 255.882 351.998 C 212.742 351.998 195.135 399.998 160.801 399.998 C 145.395 399.998 131.723 394.147 120.621 374.798 C 131.595 439.03 187.893 487.998 255.881 487.998 C 323.868 487.998 380.168 439.03 391.14 374.798 C 380.04 394.148 366.368 399.998 350.964 399.998 Z"
    />
    <g>
      <path
        style="fill:#8C7873;"
        d="M32,423.998c-3.172,0-6.18-1.906-7.43-5.031c-1.641-4.105,0.359-8.758,4.461-10.402l160.008-64
      c4.062-1.617,8.758,0.352,10.398,4.457s-0.359,8.758-4.461,10.402l-160.008,64C34,423.811,32.992,423.998,32,423.998z"
      />
      <path
        style="fill:#8C7873;"
        d="M15.992,375.995c-3.547,0-6.781-2.375-7.727-5.965c-1.125-4.273,1.422-8.648,5.695-9.773l152-40
      c4.289-1.121,8.648,1.426,9.773,5.703c1.125,4.273-1.422,8.648-5.695,9.773l-152,40C17.351,375.913,16.672,375.995,15.992,375.995z
      "
      />
      <path
        style="fill:#8C7873;"
        d="M7.992,335.995c-3.812,0-7.187-2.73-7.867-6.609c-0.773-4.352,2.133-8.5,6.484-9.27l136-24
      c4.328-0.77,8.508,2.141,9.266,6.488c0.773,4.352-2.133,8.5-6.484,9.27l-136,24C8.922,335.956,8.453,335.995,7.992,335.995z"
      />
      <path
        style="fill:#8C7873;"
        d="M480,423.998c3.172,0,6.18-1.906,7.43-5.031c1.641-4.105-0.359-8.758-4.461-10.402l-160.008-64
      c-4.063-1.617-8.758,0.352-10.398,4.457s0.359,8.758,4.461,10.402l160.008,64C478,423.811,479.007,423.998,480,423.998z"
      />
      <path
        style="fill:#8C7873;"
        d="M496.007,375.995c3.547,0,6.781-2.375,7.727-5.965c1.125-4.273-1.422-8.648-5.695-9.773l-152-40
      c-4.289-1.121-8.648,1.426-9.773,5.703c-1.125,4.273,1.422,8.648,5.695,9.773l152,40
      C494.648,375.913,495.328,375.995,496.007,375.995z"
      />
      <path
        style="fill:#8C7873;"
        d="M504.007,335.995c3.813,0,7.188-2.73,7.867-6.609c0.773-4.352-2.133-8.5-6.484-9.27l-136-24
      c-4.328-0.77-8.508,2.141-9.266,6.488c-0.773,4.352,2.133,8.5,6.484,9.27l136,24C503.078,335.956,503.546,335.995,504.007,335.995z
      "
      />
    </g>
  </svg>
-->
</template>

<script>
export default {
  name: 'ProjectLogo'
}
</script>
